import { Pressable } from '@react-aria/interactions';
import type { ForwardedRef, ReactElement, ReactNode } from 'react';
import { forwardRef } from 'react';
import type {
  DialogProps,
  DialogTriggerProps,
  PopoverProps as PrimitiveProps,
} from 'react-aria-components';
import {
  Dialog,
  DialogTrigger,
  Heading,
  OverlayArrow,
  Popover as Primitive,
  Text,
} from 'react-aria-components';

import type { PopoverVariants } from './popover.css.js';
import {
  dialogStyles,
  overlayArrowStyles,
  popoverRecipe,
  textStyles,
  titleStyles,
} from './popover.css.js';

export type PopoverProps = Omit<PrimitiveProps, 'children' | 'trigger'> &
  PopoverVariants & {
    children: DialogProps['children'];
    defaultOpen?: DialogTriggerProps['defaultOpen'];
    fixed?: boolean;
    name?: string;
    trigger: ReactElement;
  };

export function PopoverTitle({ children }: { children: ReactNode }) {
  return (
    <Heading className={titleStyles} data-test="popover-title" slot="title">
      {children}
    </Heading>
  );
}

export function PopoverText({ children }: { children: ReactNode }) {
  return (
    <Text className={textStyles} data-test="popover-text">
      {children}
    </Text>
  );
}

function Popover(
  {
    children,
    defaultOpen,
    fixed = false,
    name,
    placement,
    shouldFlip,
    trigger,
    ...props
  }: PopoverProps,
  ref: ForwardedRef<HTMLElement>,
) {
  return (
    <DialogTrigger defaultOpen={defaultOpen}>
      <Pressable>{trigger}</Pressable>
      <Primitive
        className={popoverRecipe({ fixed, placement })}
        data-test={`popover${name ? `-${name}` : ''}`}
        placement={placement}
        shouldFlip={shouldFlip}
        {...props}
      >
        <OverlayArrow data-test="popover-arrow">
          <svg className={overlayArrowStyles}>
            <path d="M0 0 L8 8 L16 0" />
          </svg>
        </OverlayArrow>
        <Dialog className={dialogStyles} data-test="popover-content" ref={ref}>
          {children}
        </Dialog>
      </Primitive>
    </DialogTrigger>
  );
}
const _Popover = forwardRef(Popover);

export { _Popover as Popover };
